import { createStore } from "vuex";
import { AxiosError, AxiosResponse } from "axios";
import {
  ApplicationConfiguration,
  ConfigApi,
  ErrorCodeAndCount,
  FileAvailabilityInfo,
  GeneratorApi,
  ImportApi,
  ImportRunLog,
  ImportRunReport,
  LogFilter,
  LogPagination,
  LogSearchParams,
  LogSorting,
  LogSortingDirectionEnum,
  ManagementApi,
  MessageOccurrence,
} from "../../generated/api/src";
import { UcsTestDataDeletionState } from "@/models/UcsTestDataDeletionState";

export interface State {
  // ui status
  testdataGenerationEnabled: boolean;
  dataset1ExportEnabled: boolean;
  dataset2ExportEnabled: boolean;
  dataset3ExportEnabled: boolean;
  logSearchParams: LogSearchParams;
  //data
  reports: ImportRunReport[];
  fileAvailabilityInfos: Array<FileAvailabilityInfo>;
  importFolderFiles: Array<FileAvailabilityInfo>;
  logSearchResultCount: number;
  importLogs: ImportRunLog[];
  errorCodesAndCounts: ErrorCodeAndCount[];
  messageOccurrences: Map<string, MessageOccurrence[]>;
  // config/environment
  testingEnabled: boolean;
  idiVersion: string;
  idiVersionCreatedAt: string;
  ucsUrl: string;
  recordId: string;
  ucsUsersDeletionState: UcsTestDataDeletionState;
  ucsClassesDeletionState: UcsTestDataDeletionState;
}

const getLogSearchParamsDefaultState = (): LogSearchParams => {
  return {
    logFilter: {
      logLevel: "ALL",
      logContext: "ALL",
      ucsDataContext: "ALL",
    } as LogFilter,
    sort: {
      attributeName: "occurredAt",
      direction: LogSortingDirectionEnum.ASC,
    } as LogSorting,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    } as LogPagination,
  };
};

export default createStore<State>({
  state: {
    testdataGenerationEnabled: true as boolean,
    dataset1ExportEnabled: true as boolean,
    dataset2ExportEnabled: true as boolean,
    dataset3ExportEnabled: true as boolean,
    logSearchParams: getLogSearchParamsDefaultState(),
    reports: [] as ImportRunReport[],
    fileAvailabilityInfos: [] as Array<FileAvailabilityInfo>,
    importFolderFiles: [] as Array<FileAvailabilityInfo>,
    logSearchResultCount: 0 as number,
    importLogs: [] as ImportRunLog[],
    errorCodesAndCounts: [] as ErrorCodeAndCount[],
    messageOccurrences: new Map<string, MessageOccurrence[]>(),
    testingEnabled: false as boolean,
    idiVersion: "" as string,
    idiVersionCreatedAt: "" as string,
    ucsUrl: "" as string,
    recordId: "" as string,
    ucsUsersDeletionState: {
      active: false,
      numberOfFailedItems: 0,
      numberOfProcessedItems: 0,
      allItemsCount: 0,
    } as UcsTestDataDeletionState,
    ucsClassesDeletionState: {
      active: false,
      numberOfFailedItems: 0,
      numberOfProcessedItems: 0,
      allItemsCount: 0,
    } as UcsTestDataDeletionState,
  },
  mutations: {
    setTestdataGenerationEnabled(state, enabled: boolean): void {
      state.testdataGenerationEnabled = enabled;
    },
    setDataset1ExportEnabled(state, enabled: boolean): void {
      state.dataset1ExportEnabled = enabled;
    },
    setDataset2ExportEnabled(state, enabled: boolean): void {
      state.dataset2ExportEnabled = enabled;
    },
    resetLogSearchParamsState(state) {
      state.logSearchParams = getLogSearchParamsDefaultState();
    },
    setLogFilter(state, newLogFilter: LogFilter): void {
      state.logSearchParams = getLogSearchParamsDefaultState();
      state.logSearchParams.logFilter = newLogFilter;
    },
    addLogSort(state, sort: LogSorting): void {
      Object.assign(state.logSearchParams.sort, sort);
    },
    addLogFilter(state, appendLogFilter: LogFilter): void {
      Object.assign(state.logSearchParams.logFilter, appendLogFilter);
    },
    setDataset3ExportEnabled(state, enabled: boolean): void {
      state.dataset3ExportEnabled = enabled;
    },
    setImportReports(state, reports: ImportRunReport[]): void {
      state.reports = reports;
    },
    setAllImportFolderFiles(
      state,
      importFolderFiles: Array<FileAvailabilityInfo>
    ): void {
      state.importFolderFiles = importFolderFiles;
    },
    setFileAvailabilityInfos(
      state,
      fileAvailabilityInfos: Array<FileAvailabilityInfo>
    ): void {
      state.fileAvailabilityInfos = fileAvailabilityInfos;
    },
    setLogSearchResultCount(state, logSearchResultCount: number): void {
      state.logSearchResultCount = logSearchResultCount;
    },
    setImportLogs(state, logs: ImportRunLog[]): void {
      state.importLogs = logs;
    },
    setErrorCodeCounts(state, errorCodeCounts: ErrorCodeAndCount[]): void {
      state.errorCodesAndCounts = errorCodeCounts;
    },
    setMessageOccurrences(
      state,
      { importRunId, messageType, ucsDataContext, logLevel, messageOccurrences }
    ): void {
      state.messageOccurrences.set(
        importRunId + "-" + messageType + "-" + ucsDataContext + "-" + logLevel,
        messageOccurrences
      );
      state.messageOccurrences = new Map<string, MessageOccurrence[]>(
        state.messageOccurrences
      );
    },
    setTestingEnabled(state, testingEnabled: boolean): void {
      state.testingEnabled = testingEnabled;
    },
    setIdiVersion(state, idiVersion: string): void {
      state.idiVersion = idiVersion;
    },
    setIdiVersionCreatedAt(state, idiVersionCreatedAt: string): void {
      state.idiVersionCreatedAt = idiVersionCreatedAt;
    },
    setUcsUrl(state, ucsUrl: string): void {
      state.ucsUrl = ucsUrl;
    },
    setRecordId(state, recordId: string): void {
      state.recordId = recordId;
    },
    setUcsUsersDeletionState(
      state,
      ucsUsersDeletionState: UcsTestDataDeletionState
    ): void {
      state.ucsUsersDeletionState = ucsUsersDeletionState;
    },
    setUcsClassesDeletionState(
      state,
      ucsClassesDeletionState: UcsTestDataDeletionState
    ): void {
      state.ucsClassesDeletionState = ucsClassesDeletionState;
    },
  },
  actions: {
    getLoginStatus(): void {
      // make a request for some data to see if user is still logged in; in case of authentication errors a relogin is
      // enforced; see axios-interceptor in idi-ui/src/main.ts
      new ConfigApi(undefined, `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`)
        .getConfig()
        .then((response: AxiosResponse<ApplicationConfiguration>) => {
          // do nothing useful with the data
          console.log("loading config succeeded", response.data);
        })
        .catch((error: Error) => {
          console.error("loading config failed", error);
        });
    },
    getConfig(context): void {
      new ConfigApi(undefined, `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`)
        .getConfig()
        .then((response: AxiosResponse<ApplicationConfiguration>) => {
          console.log("getConfig: ", response);
          console.log("getConfig-data: ", response.data);
          context.commit("setUcsUrl", response.data.ucsUrl);
          context.commit("setTestingEnabled", response.data.testing_enabled);
          context.commit("setIdiVersion", response.data.idiVersion);
          context.commit(
            "setIdiVersionCreatedAt",
            response.data.idiVersionCreatedAt
          );
        })
        .catch((error: Error) => {
          console.log(error);
          alert("Fehler! \nMeldung: " + error.message);
          return [];
        });
    },
    getAllFilesOfImportFolder(context): void {
      new ImportApi(undefined, `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`)
        .getAllImportFolderFiles()
        .then((response: AxiosResponse<Array<FileAvailabilityInfo>>) => {
          console.log(response.data);
          context.commit("setAllImportFolderFiles", response.data);
        })
        .catch((error: Error) => {
          console.log(error);
          alert("Fehler! \nMeldung: " + error.message);
          return [];
        });
    },
    getFileAvailabilityInfos(context, importRunId: number): void {
      new ImportApi(undefined, `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`)
        .checkIfFilesAreAvailableForDownload(importRunId)
        .then((response: AxiosResponse<Array<FileAvailabilityInfo>>) => {
          console.log(response.data);
          context.commit("setFileAvailabilityInfos", response.data);
        })
        .catch((error: Error) => {
          console.log(error);
          alert("Fehler! \nMeldung: " + error.message);
          return [];
        });
    },
    getAllImports(context): void {
      new ImportApi(undefined, `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`)
        .getAllImports()
        .then((response: AxiosResponse<ImportRunReport[]>) => {
          console.log("getAllImports: ", response);
          console.log("getAllImports-data: ", response.data);
          response.data.sort((a, b) => (b.id || 0) - (a.id || 0));
          context.commit("setImportReports", response.data);
        })
        .catch((error: AxiosError) => {
          console.log(error);
          if (error && error.response && error.response.status !== 401) {
            alert("Fehler! \nMeldung: " + error.message);
          }
          return [];
        });
    },
    resetImportLogs({ commit }): void {
      commit("setLogSearchResultCount", 0);
    },
    getImportLogsCount({ commit, getters }, importId): void {
      new ImportApi(undefined, `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`)
        .getImportLogsCount(importId, { ...getters.logSearchParams.logFilter })
        .then(async (response: AxiosResponse<number>) => {
          commit("setLogSearchResultCount", response.data);
        });
    },

    getImportLogs({ commit, getters }, importId): void {
      new ImportApi(undefined, `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`)
        .getImportLogs(importId, { ...getters.logSearchParams })
        .then(async (response: AxiosResponse<ImportRunLog[]>) => {
          const importRunLogs = <ImportRunLog[]>response.data;
          importRunLogs.forEach((importRunLog) => {
            try {
              importRunLog.errorDetails = importRunLog.errorDetails
                ? JSON.parse(importRunLog.errorDetails)
                : null;
            } catch (e) {
              console.warn(
                "importRunLog.errorDetails enthält keinen String im json-Format. Wahrscheinlich wurde der " +
                  "Logeintrag vor release 1.5.9 gespeichert. Die Fehlerdetails werden als String weiterverarbeitet."
              );
            }
          });
          commit("setImportLogs", importRunLogs);
        });
    },
    getErrorCodeCounts(context, importRunId: number): void {
      new ImportApi(undefined, `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`)
        .getErrorCodeCounts(importRunId)
        .then((response: AxiosResponse<ErrorCodeAndCount[]>) => {
          console.log("getErrorCodeCounts: ", response);
          console.log("getErrorCodeCounts-data: ", response.data);
          response.data.sort(
            (a, b) => (a.messageCode || 0) - (b.messageCode || 0)
          );
          context.commit("setErrorCodeCounts", response.data);
        })
        .catch((error: Error) => {
          console.log(error);
          alert("Fehler! \nMeldung: " + error.message);
          return [];
        });
    },
    getMessageOccurrences(
      context,
      { importRunId, messageType, ucsDataContext, logLevel }
    ): void {
      new ImportApi(undefined, `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`)
        .getMessageOccurrences(
          importRunId,
          messageType,
          ucsDataContext,
          logLevel
        )
        .then((response: AxiosResponse<MessageOccurrence[]>) => {
          console.log(response.data);
          const messageOccurrences: MessageOccurrence[] = response.data;
          context.commit("setMessageOccurrences", {
            importRunId,
            messageType,
            ucsDataContext,
            logLevel,
            messageOccurrences,
          });
        })
        .catch((error: Error) => {
          console.log(error);
          alert("Fehler! \nMeldung: " + error.message);
          return [];
        });
    },
    exportTestdata(context, step: number) {
      switch (step) {
        case 0:
          context.commit("setDataset1ExportEnabled", false);
          break;
        case 1:
          context.commit("setDataset2ExportEnabled", false);
          break;
        case 2:
          context.commit("setDataset3ExportEnabled", false);
          break;
      }
      new GeneratorApi(
        undefined,
        `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`
      )
        .exportTestdata(step)
        .then((response: AxiosResponse<void>) => {
          console.log(response.data);
        })
        .catch((error: Error) => {
          console.log(error);
          alert(
            "Fehler! Mögliche Ursache: Funktion auf aktueller Umgebung nicht aktiv. \nMeldung: " +
              error.message
          );
        })
        .finally(() => {
          switch (step) {
            case 0:
              context.commit("setDataset1ExportEnabled", true);
              break;
            case 1:
              context.commit("setDataset2ExportEnabled", true);
              break;
            case 2:
              context.commit("setDataset3ExportEnabled", true);
              break;
          }
        });
    },
    triggerImportManually(): void {
      new ImportApi(undefined, `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`)
        .triggerImportManually()
        .then((response: AxiosResponse<number>) => {
          console.log(response.data);
        })
        .catch((error: Error) => {
          console.log(error);
          alert(
            "Fehler! Mögliche Ursache: Ein anderer Prozess ist bereits aktiv. \nMeldung: " +
              error.message
          );
        });
    },
    triggerTestdataGeneration(context): void {
      context.commit("setTestdataGenerationEnabled", false);
      new GeneratorApi(
        undefined,
        `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`
      )
        .generateTestdata()
        .then((response: AxiosResponse<void>) => {
          console.log(response.data);
        })
        .catch((error: Error) => {
          console.log(error);
          alert(
            "Fehler! Mögliche Ursachen: Funktion auf Umgebung nicht aktiv.\nMeldung: " +
              error.message
          );
        })
        .finally(() => {
          context.commit("setTestdataGenerationEnabled", true);
        });
    },
    deleteTestdataUsers(): void {
      new ManagementApi(
        undefined,
        `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`
      )
        .deleteIamUsers()
        .then((response: AxiosResponse<void>) => {
          console.log(response.data);
        })
        .catch((error: Error) => {
          console.log(error);
          alert(
            "Fehler! Mögliche Ursachen: Funktion nicht aktiv oder ein anderer Prozess läuft bereits. \nMeldung: " +
              error.message
          );
        });
    },
    deleteTestdataClasses(): void {
      new ManagementApi(
        undefined,
        `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`
      )
        .deleteIamClasses()
        .then((response: AxiosResponse<void>) => {
          console.log(response.data);
        })
        .catch((error: Error) => {
          console.log(error);
          alert(
            "Fehler! Mögliche Ursachen: Funktion nicht aktiv oder ein anderer Prozess läuft bereits. \nMeldung: " +
              error.message
          );
        });
    },
    calculateRecordId(context, divisId: string): void {
      new ManagementApi(
        undefined,
        `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`
      )
        .calculateRecordId(divisId)
        .then((response: AxiosResponse<string>) => {
          context.commit("setRecordId", response.data);
        })
        .catch((error: Error) => {
          console.log(error);
          alert(
            "Fehler! RecordId konnte nicht berechnet werden." + error.message
          );
        });
    },
    setBackendMessageContent(context, messageContent: string): void {
      const message = JSON.parse(messageContent);
      if (message.messageType === "UCS_USER_DELETION_STATE") {
        context.commit("setUcsUsersDeletionState", message);
      } else if (message.messageType === "UCS_CLASS_DELETION_STATE") {
        context.commit("setUcsClassesDeletionState", message);
      }
    },
    restartApplication(): void {
      new ManagementApi(
        undefined,
        `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}`
      )
        .restartApplication()
        .catch((error: Error) => {
          console.log(error);
          alert("Fehler beim Neustart der Anwendung." + error.message);
        });
    },
  },
  getters: {
    testdataGenerationEnabled: (state) => state.testdataGenerationEnabled,
    dataset1ExportEnabled: (state) => state.dataset1ExportEnabled,
    dataset2ExportEnabled: (state) => state.dataset2ExportEnabled,
    dataset3ExportEnabled: (state) => state.dataset3ExportEnabled,
    logSearchParams: (state) => state.logSearchParams,
    logSearchResultCount: (state) => state.logSearchResultCount,
    importLogs: (state) => state.importLogs,
    reports: (state) => state.reports,
    fileAvailabilityInfos: (state) => state.fileAvailabilityInfos,
    importFolderFiles: (state) => state.importFolderFiles,
    errorCodeCounts: (state) => state.errorCodesAndCounts,
    messageOccurrences: (state) => state.messageOccurrences,
    testingEnabled: (state) => state.testingEnabled,
    ucsUrl: (state) => state.ucsUrl,
    idiVersion: (state) => state.idiVersion,
    idiVersionCreatedAt: (state) => state.idiVersionCreatedAt,
    recordId: (state) => state.recordId,
    ucsUsersDeletionState: (state) => state.ucsUsersDeletionState,
    ucsClassesDeletionState: (state) => state.ucsClassesDeletionState,
  },
  modules: {},
});
